
import {
  computed,
  defineComponent,
} from 'vue';
import importComponents from '@/utils/import-components';
import { Dropdown, Menu } from 'ant-design-vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'GoodsCategory',
  components: {
    ...importComponents(
      Dropdown,
      Menu,
      Menu.Item,
    ),
  },
  setup() {
    const store = useStore();
    const categories = computed(() => store.state.categories);
    const dropdownStyle = {
      'min-width': '180px',
    };
    return {
      categories,
      dropdownStyle,
    };
  },
});
