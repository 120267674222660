interface Component {
  name: string;
}

export default function importComponents(...components: Component[]) {
  return components.reduce((result, component) => {
    Reflect.set(result, component.name, component);
    return result;
  }, {} as Record<string, object>);
}
