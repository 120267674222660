
import importComponents from '@/utils/import-components';
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'errorIcon',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    return {
    };
  },
});
