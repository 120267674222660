
import importComponents from '@/utils/import-components';
import { useI18n } from 'vue-i18n';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { Modal } from 'ant-design-vue';

export default defineComponent({
  name: 'CookiePolicy',
  components: {
    ...importComponents(Modal),
  },
  setup() {
    const { t } = useI18n();
    const modalVisible = ref<boolean>(false);
    const cookieVisible = ref<boolean>(true);
    // 隐私政策弹框
    const handleModal = () => {
      modalVisible.value = true;
    };
    const handleClick = () => {
      localStorage.setItem('cookiePolicy', 'true');
      cookieVisible.value = false;
    };

    const table = {
      th: [
        { th: t('cookie.table.th.th1') },
        { th: t('cookie.table.th.th2') },
        { th: t('cookie.table.th.th3') },
      ],
      td: [
        [
          t('cookie.table.td.td1'),
          t('cookie.table.td.td2'),
          t('cookie.table.td.td3'),
        ],
        [
          t('cookie.table.td.td4'),
          t('cookie.table.td.td5'),
          t('cookie.table.td.td6'),
        ],
        [
          t('cookie.table.td.td7'),
          t('cookie.table.td.td8'),
          t('cookie.table.td.td9'),
        ],
        [
          t('cookie.table.td.td10'),
          t('cookie.table.td.td11'),
          t('cookie.table.td.td12'),
        ],
        [
          t('cookie.table.td.td13'),
          t('cookie.table.td.td14'),
          t('cookie.table.td.td15'),
        ],
        [
          t('cookie.table.td.td16'),
          t('cookie.table.td.td17'),
          t('cookie.table.td.td18'),
        ],
        [
          t('cookie.table.td.td19'),
          t('cookie.table.td.td20'),
          t('cookie.table.td.td21'),
        ],
        [
          t('cookie.table.td.td22'),
          t('cookie.table.td.td23'),
          t('cookie.table.td.td24'),
        ],
        [
          t('cookie.table.td.td25'),
          t('cookie.table.td.td26'),
          t('cookie.table.td.td27'),
        ],
      ],
    };
    const browser = [
      'Firefox',
      'Chrome',
      'Internet',
      'Explorer',
      'Safari',
      'Opera',
    ];

    onBeforeMount(() => {
      if (!localStorage.getItem('cookiePolicy')) {
        cookieVisible.value = true;
      } else {
        cookieVisible.value = false;
      }
    });
    return {
      t,
      modalVisible,
      handleModal,
      table,
      browser,
      handleClick,
      cookieVisible,
    };
  },
});
