
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'IndexSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
    };
  },
});
