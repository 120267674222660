<template>
    <div class="r-goods-categories">
        <a-dropdown
            placement="bottomLeft"
            v-for="category in categories"
            :key="category.id"
            :overlayStyle="dropdownStyle"
        >
            <router-link
                class="r-goods-category"
                :to="`/category/${category.text}`"
            >{{ category.name }}</router-link>
            <template #overlay v-if="category.subcategories">
                <a-menu>
                    <a-menu-item v-for="subCategory in category.subcategories" :key="subCategory.id">
                        <router-link
                            :to="`/category/${category.text}/goods/${subCategory.text}`"
                        >{{ subCategory.name }}</router-link>
                    </a-menu-item>
                </a-menu>
            </template>
        </a-dropdown>
    </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import importComponents from '@/utils/import-components';
import { Dropdown, Menu } from 'ant-design-vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'GoodsCategory',
  components: {
    ...importComponents(
      Dropdown,
      Menu,
      Menu.Item,
    ),
  },
  setup() {
    const store = useStore();
    const categories = computed(() => store.state.categories);
    const dropdownStyle = {
      'min-width': '180px',
    };
    return {
      categories,
      dropdownStyle,
    };
  },
});
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
.r-goods-categories {
    height: 55px;
    .r-goods-category {
        color: $font-color;
        font-size: 14px;
        font-weight: $font-weight;
        padding: unset;
        line-height: 55px;
        display: inline-block;
        margin-right: 119px;
        &:hover {
            color: $font-color;
        }
    }
}
</style>
