
import importComponents from '@/utils/import-components';
import {
  computed, defineComponent,
} from 'vue';
import { useStore } from 'vuex';
import IndexCartAction from '@/components/index/IndexCartAction.vue';
import { useRouter, useRoute } from 'vue-router';
import { Dropdown, Menu, Button } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { touristToken } from '@/services';
import http from '@/utils/http';
import GoodsSearch from './GoodsSearch.vue';
import GoodsCategory from './GoodsCategory.vue';
import RegionSelector from './RegionSelector.vue';

export default defineComponent({
  name: 'Header',
  components: {
    GoodsSearch,
    IndexCartAction,
    RegionSelector,
    GoodsCategory,
    ...importComponents(
      Dropdown,
      Menu,
      Menu.Item,
      Button,
    ),
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { state, dispatch } = useStore();
    const { t } = useI18n();

    const userFirstName = computed(() => state.userInfo.userFirstName);

    const email = computed(() => state.userInfo.userEmail);

    const cartNumber = computed(() => state.cartNumber);

    const cartLists = computed(() => state.cartStore.cartItems);

    const signOut = () => {
      dispatch('signOut');
      router.push('/');
      // 清空登录状态的购物车
      dispatch('cleanCart');
      http.clearAuthorization();
      if (!localStorage.getItem('touristToken')) {
        localStorage.setItem('touristToken', Date.parse(new Date().toString()) + Math.random().toFixed(2).toString());
        touristToken(localStorage.getItem('touristToken') as string);
      }
    };

    const toCart = () => {
      dispatch('getCartItems');
      if (cartLists.value.length > 0 || cartNumber.value) {
        router.push('/shopping-cart');
      }
    };

    return {
      route,
      router,
      userFirstName,
      signOut,
      toCart,
      t,
      email,
    };
  },
});
