
import { defineComponent } from 'vue';
import CartBtn from '@/components/common/CartBtn.vue';
import PictureBox from '@/components/common/PictureBox.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexGoodsCard',
  props: {
    goodsId: {
      type: Number,
      requried: true,
    },
    skuId: {
      type: [String, Number],
      requried: true,
    },
    img: {
      type: String,
      requried: true,
    },
    goodsName: {
      type: String,
      requried: true,
    },
    currentPrice: {
      type: [Number, String],
      requried: true,
    },
    originalPrice: {
      type: [Number, String],
      requried: true,
    },
    goodsAttr: {
      type: Object,
    },
    realityStock: {
      type: Number,
    },
    btn: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CartBtn,
    PictureBox,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    return {
      router,
      t,
    };
  },
});
