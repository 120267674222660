/*
 * @Author: your name
 * @Date: 2020-12-28 14:08:53
 * @LastEditTime: 2021-01-27 15:06:42
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/components/Alert/index.ts
 */
import { Modal } from 'ant-design-vue';
import { createVNode } from 'vue';
import './style.scss';
import successIcon from './successIcon.vue';
import errorIcon from './errorIcon.vue';

export class Alert {
  public static success(title: string) {
    return Modal.success({
      centered: true,
      title,
      class: 'success-alert',
      maskClosable: false,
      icon: createVNode(successIcon),
    });
  }

  public static error(title: string, canClose?: boolean) {
    return Modal.error({
      centered: true,
      title,
      class: 'success-alert',
      maskClosable: false || canClose,
      icon: createVNode(errorIcon),
    });
  }
}

export default {};
