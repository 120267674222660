import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/user/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/user/Profile.vue'),
  },
  {
    path: '/user/change-name',
    name: 'ChangeName',
    component: () => import(/* webpackChunkName: "change-name" */ '@/views/user/ChangeName.vue'),
  },
  {
    path: '/user/change-password',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/user/ChangePassword.vue'),
  },
  {
    path: '/user/my-orders',
    name: 'MyOrders',
    component: () => import(/* webpackChunkName: "my-orders" */ '@/views/order/MyOrders.vue'),
  },
  {
    path: '/user/orders-details',
    name: 'OrdersDetails',
    component: () => import(/* webpackChunkName: "orders-details" */ '@/views/order/OrdersDetails.vue'),
    meta: {
      orderData: '',
    },
  },
  {
    path: '/user/logistics',
    name: 'Logistics',
    component: () => import(/* webpackChunkName: "logistics" */ '@/views/order/Logistics.vue'),
  },
];

export default routes;
