<template>
    <div class="index-section">
      <div class="section-title">{{title}}</div>
      <slot/>
    </div>
</template>

<script lang='ts'>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'IndexSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
    };
  },
});
</script>
<style lang="scss">
@import '@/assets/styles/variables.scss';
.index-section {
  padding: 52px 0 0;
  .section-title {
    text-align: center;
    font-size: 30px;
    color: $font-color;
    font-weight: 800;
  }
}
</style>
