<template>
    <a-dropdown :getPopupContainer="(triggerNode) => triggerNode.parentNode">
        <div class="region-selector">
            <img :src="availableLocales[locale].icon" alt="region" />
            <span>{{ availableLocales[locale].text }}</span>
        </div>
        <template #overlay>
        <a-menu class="region-list">
            <a-menu-item v-for="locale in availableLocales" :key="locale.code" class="region-list-item">
                <a :href="`//${isPre ? 'preview-' + locale.prefix + '.rightphone.com' : (`${locale.prefix}.rightphone.com`)}`"
                    :title="locale.text">
                    <img :src="locale.icon" :alt="locale.prefix" />{{ locale.text }}</a>
            </a-menu-item>
        </a-menu>
        </template>
    </a-dropdown>
</template>

<script lang='ts'>
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { Dropdown, Menu } from 'ant-design-vue';
import { availableLocales } from '@/i18n';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RegionSelector',
  components: {
    ...importComponents(Dropdown, Menu, Menu.Item),
  },
  setup() {
    const { locale } = useI18n();

    return {
      availableLocales,
      locale,
      isPre: process.env.VUE_APP_IS_PRE,
    };
  },
});
</script>
<style lang='scss'>
.region-selector {
    cursor: pointer;
    img {
        width: 24px;
    }
    > span {
        padding-left: 10px;
    }
}
.region-list {
    &-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #969696;
        img {
            width: 18px;
            margin-right: 15px;
        }
    }
}
</style>
