<template>
    <a-carousel arrows class="goods-swiper"
        :slidesToShow="cardNum"
        :slidesToScroll="cardNum"
        :variableWidth="variableWidth"
        :dots="false"
        adaptiveHeight>
        <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; zindex: 1">
                <left-icon />
            </div>
        </template>
        <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
                <right-icon />
            </div>
        </template>
        <index-goods-card v-for="(item) in goods" :key="item.skuId"
            :goodsId="item.goodsId"
            :skuId="item.skuId"
            :img="item.img"
            :goodsName="item.name"
            :currentPrice="item.price"
            :originalPrice="item.originalPrice"
            :goodsAttr="item.goodsAttr"
            :btn="false"/>
    </a-carousel>
</template>
<script lang='ts'>
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue';
import { Carousel } from 'ant-design-vue';
import IndexGoodsCard from '@/components/index/IndexGoodsCard.vue';

interface Goods {
  img: string;
  name: string;
  currentPrice: string;
  originalPrice: string;
  skuId: string;
  goodsAttr: {
    capacity: string;
    carrier: string;
    color: string;
  };
}

export default defineComponent({
  name: 'GoodsSwiper',
  props: {
    goods: {
      type: Array as () => Goods[],
      required: true,
    },
  },
  components: {
    IndexGoodsCard,
    'left-icon': LeftCircleFilled,
    'right-icon': RightCircleFilled,
    ...importComponents(
      Carousel,
    ),
  },
  setup() {
    // 轮播图根据窗口可视宽度展示
    const cardNum = ref(5);

    const variableWidth = ref(false);

    const setCardNum = () => {
      if (document.body.clientWidth <= 1280) {
        variableWidth.value = false;
        cardNum.value = 4;
      } else {
        variableWidth.value = false;
        cardNum.value = 5;
      }
    };

    window.onresize = () => {
      setCardNum();
    };

    onBeforeMount(() => {
      setCardNum();
    });

    return {
      variableWidth,
      cardNum,
    };
  },
});
</script>
<style lang='scss'>
.goods-swiper {
    max-width: 1720px;
    min-width: 1052px;
    height: 380px;
}

.ant-carousel{
    .slick-list {
        margin: 0 98px;
        }
    .slick-slide, .slick-current {
        &>div {
            // width: 268px;
            margin: auto;
        }
        .card {
            width: 93%!important;
            margin: 52px 15px 86px;

            &>img {
                padding: 0 28px;
            }
            .text {
                padding-bottom: 30px;
            }
            &:hover .ant-btn-primary {
                width: 80%;
            }
            .goods-img{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 65%;
            }
        }
    }
}
.ant-carousel .slick-arrow.custom-slick-arrow {
    width: 68px;
    height: 68px;
    font-size: 68px;
    color: #a2a2a2;
    background-color: #ffffff;
    border-radius: 68px;
    top: 40%;
}
.ant-carousel .custom-slick-arrow:before {
    display: none;
}
.ant-carousel .custom-slick-arrow:hover {
    opacity: 0.5;
}
.swiper {
    display: flex !important;
    padding: 40px 100px;
}
.ant-carousel .slick-slide img {
    display: unset;
}
</style>
