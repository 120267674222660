
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import IndexBanner from '@/components/index/IndexBanner.vue';
import Service from '@/components/index/Service.vue';
import IndexSection from '@/components/index/IndexSection.vue';
import importComponents from '@/utils/import-components';
import GoodsSwiper from '@/components/common/GoodsSwiper.vue';
// eslint-disable-next-line import/no-cycle
import { indexActivity } from '@/services';
import type { Goods } from '@/services/interface/response';
import PictureBox from '@/components/common/PictureBox.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Home',
  components: {
    IndexBanner,
    Service,
    IndexSection,
    GoodsSwiper,
    PictureBox,
    ...importComponents(
    ),
  },
  setup() {
    const goods = ref<Goods[]>([]);
    const { t } = useI18n();

    const accessories = [
      {
        img: '/images/index/lightening.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/dataLine.png',
        name: 'Bluetooth headset',
        price: '479.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/Heaphone-AccBundBox.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/Plug.jpg',
        name: 'Bluetooth headset',
        price: '479.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/watch-charger.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
    ];

    const getIphoneSwiper = async () => {
      const res = await indexActivity();
      if (res.success) {
        goods.value = res.data.iphones.map((item) => ({
          ...item,
          img: `${item.img}?x-oss-process=image/resize,w_130,h_160`,
        }));
      }
    };

    onBeforeMount(getIphoneSwiper);

    return {
      goods,
      accessories,
      t,
    };
  },
});
