<template>
    <img src="../../assets/icons/success.svg" alt="successIcon" role="alert">
</template>
<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'successIcon',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    return {
    };
  },
});
</script>
<style lang="scss">
</style>
