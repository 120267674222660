<template>
    <a-carousel autoplay>
        <!-- <div class="banner">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/banner/banner1.png" alt="banner" />
        </div> -->
        <div class="banner">
          <!-- <a href="https://www.k-check.pro/" target="_blank" title="www.k-check.pro" >
            <img :src="`https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/banner/banner2-${/(en_IE|en_NL)/.test(locale) ? 'en_GB' : locale}.png`" alt="banner" />
          </a> -->
          <!-- <a href="/category/apple/goods/iphone-xr" title="iphone-xr"> -->
            <img :src="`https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/banner/banner2-${/(en_IE|en_NL)/.test(locale) ? 'en_GB' : locale}.png`" alt="banner" />
          <!-- </a> -->
        </div>
    </a-carousel>
</template>
<script lang='ts'>
import importComponents from '@/utils/import-components';
import { Carousel } from 'ant-design-vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexBanner',
  components: {
    ...importComponents(Carousel),
  },
  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
});
</script>
<style lang='scss' scoped>
.ant-carousel :deep(.slick-list) {
  margin: 0;
  div {
    width: 100%;
    margin: 0;
  }
}
</style>
