import {
  cartItem, cartPrice, deleteCart, updateCart,
} from '@/services/cart';
import Cookies from 'js-cookie';
import type { Module } from 'vuex';

// interface
interface CartItem {
  cartId: string;
  goodsId: string;
  goodsName: string;
  goodsPic: string;
  marketPrice: string;
  quantity: number;
  realityStock: number;
  sellPrice: string;
  skuAttr: {
    capacity: string;
    carrier: string;
    color: string;
  };
}

interface CartPrice {
  marketPrice: string;
  reducedPrice: string;
  sellPrice: string;
}

interface State {
  cartItems: CartItem[];
  cartPrice: CartPrice;
  cartIdArray: number[];
}

const initialState: State = {
  cartItems: [],
  cartPrice: {
    marketPrice: '',
    reducedPrice: '',
    sellPrice: '',
  },
  cartIdArray: [], // 支付用
};

const cartStore: Module<State, {}> = {
  state: initialState,
  mutations: {
    updateCartItems(state, payload: CartItem[]) {
      state.cartItems = payload;
    },
    setPrice(state, payload) {
      state.cartPrice = payload;
    },
    setCartId(state, payload) {
      state.cartIdArray = payload;
    },
    initCart(state) {
      state.cartItems = [];
      state.cartPrice = {
        marketPrice: '',
        reducedPrice: '',
        sellPrice: '',
      };
      state.cartIdArray = [];
    },
  },
  actions: {
    // 获取购物车价格
    async getPrice(ctx) {
      const res = await cartPrice();
      if (res.success) {
        ctx.commit('setPrice', res.data);
      }
    },

    // 获取购物车商品
    async getCartItems(ctx) {
      const res = await cartItem();
      const { success, data } = res;
      if (success) {
        ctx.commit('updateCartItems', data);
        const cartIds = data.map(({
          cartId,
        }) => ({
          cartId,

        }));

        data.forEach((item) => {
          if (item.quantity > item.realityStock) {
            // eslint-disable-next-line no-param-reassign
            item.quantity = item.realityStock;
            ctx.dispatch('updateCartItems', {
              cartId: item.cartId,
              goodsId: item.goodsId,
              quantity: item.quantity,
            });
          }
        });

        ctx.dispatch('getPrice');

        ctx.commit('setCartId', cartIds.map((item) => item.cartId));
      } else {
        ctx.commit('initCart');
        ctx.commit('updateCartNumber', 0);
        Cookies.set('bagNumber', '0');
      }
    },

    // 删除购物车个别商品
    async removeCartItem(ctx, payload) {
      const res = await deleteCart({
        cartId: payload,
      });
      if (res.success) {
        // eslint-disable-next-line no-param-reassign
        const newValue = ctx.state.cartItems.filter((item) => item.cartId !== payload);
        ctx.commit('updateCartItems', newValue);

        // 更改Header上的 cartNumber
        ctx.commit('setUserCartNum', res.data.quantity);
        ctx.commit('setCartId', ctx.state.cartIdArray.filter((i) => i !== payload));

        const result = await cartPrice();
        if (result.success) {
          ctx.commit('setPrice', result.data);
        }
      }
    },

    // 更新购物车商品
    async updateCartItems(ctx, payload) {
      const res = await updateCart(payload);
      if (res.success) {
        ctx.commit('updateCartItems', res.data.cartList);
        // 更改Header上的 cartNumber
        ctx.commit('setUserCartNum', res.data.quantity);
        const result = await cartPrice();
        if (result.success) {
          ctx.commit('setPrice', result.data);
        }
      }
    },

    async cleanCart(ctx) {
      ctx.commit('initCart');
      ctx.commit('updateCartNumber');
      Cookies.remove('cartNumber');
      ctx.commit('updateCartNumber', 0);
    },

  },
};
export default cartStore;
