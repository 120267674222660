import Cookies from 'js-cookie';
import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/user';

/**
 * @description: 用户注册
 * @param {Request} data
 * @return {*}
 */
export function register(data: Request.Register) {
  return http.client<Response.IsSuccess>(`${modulePath}/common/register`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 用户登录
 * @param {Request} data
 * @return {*}
 */
export async function login(data: Request.Login) {
  const res = await http.client<Response.GetToken>('/login', {
    method: 'POST',
    data,
  });
  if (res.success) {
    http.setAuthorization(res.data);
  }
  return res;
}

/**
 * @description: 获取用户信息：登录后展示lastName
 */
export function getUserInfo() {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.User>(`${modulePath}/info`);
}

/**
 * @description: 修改密码
 * @param {Request} data
 */
export function updatePassword(data: Request.NewPassword) {
  return http.client<Response.IsSuccess>(`${modulePath}/update_password`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 修改用户姓名
 * @param {Request} data
 */
export function updateUserName(data: Request.NewUserName) {
  return http.client<Response.IsSuccess>(`${modulePath}/update_user_info`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: checkout 添加地址
 * @param {Request} data
 */
export function addAddress(data: Request.Address) {
  return http.client<Response.AddressId>(`${modulePath}/add_check_out`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: checkout 修改、更新地址
 * @param {Request} data
 */
export function changeAddress(data: Request.Address, params: string) {
  return http.client<Response.IsSuccess>(`${modulePath}/change_user_address?addressId=${params}`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: checkout 查询地址
 */
export function getAddress() {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.UserAddress>(`${modulePath}/get_user_address`);
}

/**
 * @description: checkout 查询用户订单
 */
export function getOrder(params: Request.getOrder) {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.UserOrder>(`${modulePath}/get_user_order`, {
    params,
  });
}

/**
 * @description: checkout 查询用户订单详情——商品
 */
export function getOrderDetailGoods(params: Request.OrderNumber) {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.OrderDetailGoodsList>(`${modulePath}/get_user_order_goods`, {
    params,
  });
}

/**
 * @description: checkout 查询用户订单详情——地址
 */
export function getOrderDetailAddress(params: Request.OrderNumber) {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.OrderDetailAddressList>(`${modulePath}/get_user_order_detail`, {
    params,
  });
}

/**
 * @description: 发送忘记密码的邮件
 * @param {object} data
 * @return {*}
 */
export function sendEmailCode(params: Request.Email) {
  return http.client<Response.IsSuccess>(`${modulePath}/common/send_forget_password_code`, {
    params,
  });
}

/**
 * @description: 忘记密码后，重置密码
 * @param {Request} data
 * @return {*}
 */
export function forgotPassword(data: Request.ForgotPassword) {
  return http.client<Response.IsSuccess>(`${modulePath}/common/forget_password`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: contact 发送信息
 * @param {Request} data
 * @return {*}
 */
export function contactMessage(data: Request.SendMessage) {
  return http.client<Response.SendMessage>('/contact/send_message', {
    method: 'POST',
    data,
  });
}

/**
 * @description: Returns Information 发送信息
 * @param {Request} data
 * @return {*}
 */
export function ReturnsInformationMessage(data: Request.ReturnMessage) {
  return http.client<Response.SendMessage>('/contact/send_return', {
    method: 'POST',
    data,
  });
}

/**
 * @description: 获取支付token，避免订单重复提交
 * @param {*}
 * @return {*}
 */
export function payToken() {
  return http.client<Response.AddressId>(`${modulePath}/get_signature`);
}

/**
 * @description: 第三方登录
 * @param {*}
 * @return {*}
 */
export function otherLogin(source: string) {
  return http.client<Response.GoogleLogin>(`${modulePath}/common/render?source=${source}`);
}

/**
 * @description: 第三方登录的token
 * @param {*}
 * @return {*}
 */
export function otherLoginToken(renderAuthState: string) {
  return http.client<Response.AddressId>(`${modulePath}/common/get_render_token?renderAuthState=${renderAuthState}`);
}

export default {};
