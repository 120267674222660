
import importComponents from '@/utils/import-components';
import { computed, defineComponent, watch } from 'vue';
import { Popover, Badge } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexCartAction',
  components: {
    ...importComponents(
      Popover,
      Badge,
    ),
  },
  setup() {
    const { t } = useI18n();

    const { state } = useStore();

    const cartNumber = computed(() => state.cartNumber);

    const cartLists = computed(() => state.cartStore.cartItems);

    const shakeState = computed(() => state.cartShake);

    return {
      cartNumber,
      shakeState,
      cartLists,
      t,
    };
  },
});
