<template>
    <!-- <div class="ad"></div> -->
    <!-- <a-config-provider :locale="zhTW"> -->
        <GlobalHeader />
        <main class="r-global-main">
            <router-view v-slot="{ Component }">
                <keep-alive include="GoodsListForCategory">
                    <component :is="Component" />
                </keep-alive>
            </router-view>
            <a-back-top>
            <img src="/images/backTop.svg" alt="backTop" />
            </a-back-top>
        </main>
        <GlobalFooter />
    <CookiePolicy/>

    <!-- </a-config-provider> -->
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
} from 'vue';
import Cookies from 'js-cookie';
import {
  BackTop, Breadcrumb, ConfigProvider,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
import importComponents from '@/utils/import-components';
import GlobalFooter from '@/components/common/GlobalFooter';
import GlobalHeader from '@/components/common/GlobalHeader';
import { touristToken } from '@/services';
import { useStore } from '@/store';
import http from '@/utils/http';
import CookiePolicy from '@/components/footerlink/CookiePolicy.vue';

export default defineComponent({
  name: 'Home',
  components: {
    GlobalFooter,
    GlobalHeader,
    CookiePolicy,
    ...importComponents(
      BackTop,
      Breadcrumb,
      Breadcrumb.Item,
      ConfigProvider,
    ),
  },

  setup() {
    const { dispatch } = useStore();
    const router = useRouter();

    http.initialize(router, dispatch);

    onBeforeMount(() => {
      dispatch('getGoodsCategory');
      if (Cookies.get('token')) {
        dispatch('getUser');
        dispatch('getUserCartNum');
      } else {
        dispatch('initVisitorCart');
        if (!localStorage.getItem('touristToken')) {
          localStorage.setItem('touristToken', Date.parse(new Date().toString()) + Math.random().toFixed(2).toString());
          touristToken(localStorage.getItem('touristToken') as string);
        }
      }
    });

    window.name = 'rightphone';

    return {
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";

@font-face {
    font-family: Inter;
    font-weight: 700;
    src: url('/fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
    font-family: Inter;
    font-weight: 400;
    src: url('/fonts/Inter-Regular.woff2') format('woff2');
}

html,
body {
  margin: 0;
  padding: 0;
  min-width: 767px;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // ::-webkit-scrollbar { /* Chrome */
  //   display: none;
  // }
}
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.r-global-main {
    min-height: calc(100vh - 144px);
}
.ant-back-top,
.ant-back-top > img {
  width: 56px;
  height: 56px;
}
.ant-input:hover {
  border-color: $theme-color;
}
.ant-input:focus,
.ant-input-affix-wrapper:focus {
  border-color: $theme-color;
  box-shadow: $box-shadow;
}
</style>
