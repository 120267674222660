
import {
  defineComponent,
  onMounted,
  ref,
} from 'vue';

export default defineComponent({
  name: 'PictureBox',
  props: {
    src: String,
    alt: String,
  },
  setup() {
    const img = ref<HTMLImageElement>();

    if (IntersectionObserver) {
      if (window.io === undefined) {
        window.io = new IntersectionObserver((ioes) => {
          ioes.forEach((ioe) => {
            const el = ioe.target as HTMLImageElement;
            const { intersectionRatio } = ioe;
            if (intersectionRatio > 0 && intersectionRatio <= 1) {
              if (!el.src) {
                el.src = el.dataset.src ?? '';
              }
              window.io.unobserve(el);
            }
          });
        });
      }
    }

    onMounted(() => {
      if (img.value) {
        if (window.io) {
          window.io.observe(img.value);
        } else {
          img.value.src = img.value.dataset.src as string;
        }
      }
    });

    return {
      img,
    };
  },
});
