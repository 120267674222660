<template>
  <a-button type="primary" @click="addToCart">
    <template #icon>
      <img
        src="@/assets/icons/cartBtnIcon.svg"
        alt="cartBtnIcon"
        class="cart-btn-icon"
      />
      </template>{{t('cartBtn.p1')}}
  </a-button>
</template>
<script lang='ts'>
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { Button } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CartBtn',
  components: {
    ...importComponents(Button),
  },
  props: {
    goodsId: {
      type: Number,
      requried: true,
    },
    realityStock: {
      type: Number,
      requried: true,
    },
    skuId: {
      type: [String, Number],
      requried: true,
    },
  },
  setup(props) {
    const { dispatch } = useStore();
    const { t } = useI18n();

    const addToCart = (e: MouseEvent) => {
      e.stopPropagation();
      dispatch('ADDTOCART', {
        goodsId: props.goodsId,
        skuId: props.skuId,
        realityStock: props.realityStock,
        quantity: 1,
      });
      dispatch('changeCartShake').then(() => {
        setTimeout(() => {
          dispatch('changeCartShake');
        }, 3000);
      });
    };

    return {
      addToCart,
      t,
    };
  },
});
</script>
<style lang='scss' scoped>
.ant-btn-primary {
  display: none;
}
</style>
