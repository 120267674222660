<template>
  <footer class="r-global-footer">
    <div class="r-container">
      <div class="above">
        <div class="logo">
          <img src="@/assets/logo.svg" alt="logo" />
        </div>
        <div class="footer-link">
          <div class="footer-col">
            <h3>{{ t("global.footer.information") }}</h3>
            <ul>
              <router-link to="/aboutus" active-class="selected">
                <li>{{ t("global.footer.aboutUs") }}</li>
              </router-link>
              <router-link to="/device-conditions" active-class="selected">
                <li>{{ t("global.footer.deviceConditions") }}</li>
              </router-link>
              <router-link to="/warranty-information" active-class="selected">
                <li>{{ t("global.footer.warrantyInformation") }}</li>
              </router-link>
              <router-link to="/returns-information" active-class="selected">
                <li>{{ t("global.footer.returnsInformation") }}</li>
              </router-link>
              <router-link to="/shipping-information" active-class="selected">
                <li>{{ t("global.footer.shippingInformation") }}</li>
              </router-link>
            </ul>
          </div>
          <div class="footer-col">
            <h3></h3>
            <ul>
              <router-link to="/faqs" active-class="selected">
                <li>{{ t("global.footer.faq") }}</li>
              </router-link>
              <router-link to="/contact" active-class="selected">
                <li>{{ t("global.footer.contactUs") }}</li>
              </router-link>
              <router-link to="/payment-method" active-class="selected">
                <li>{{ t("global.footer.paymentMethod") }}</li>
              </router-link>
              <router-link to="/privacy-policy" active-class="selected">
                <li>{{ t("global.footer.privacyPolicy") }}</li>
              </router-link>
              <router-link to="/cookie-policy" active-class="selected">
                <li>{{ t("global.footer.cookiesPolicy") }}</li>
              </router-link>
            </ul>
          </div>
          <div class="footer-icon">
            <h3>{{ t("global.footer.weAccept") }}</h3>
            <div class="payment">
              <img
                v-for="img in payment"
                :key="img"
                :src="img"
                alt="paymentImg"
              />
            </div>
            <div class="r2">
              <img src="/images/checkout/checkout.svg" alt="responsible recycling" class="right-img">
            </div>
          </div>
        </div>

        <!-- <table class="link" cellpadding="4">
          <thead>
            <tr>
              <th>{{ t("global.footer.companyInfo") }}</th>
              <th>{{ t("global.footer.helpAndSupport") }}</th>
              <th>{{ t("global.footer.customerCare") }}</th>
              <th>{{ t("global.footer.weAccept") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <router-link to="/aboutus" active-class="selected">
                  {{ t("global.footer.aboutUs") }}
                </router-link>
              </td>
              <td>
                <router-link to="/support-index" active-class="selected">
                  {{ t("global.footer.supportHelpDocs") }}
                </router-link>
              </td>
                <td>
                <router-link to="/faqs" active-class="selected">
                  {{ t("global.footer.faqs") }}
                </router-link>
              </td>
              <td>
                <div class="payment">
                  <img
                    v-for="img in payment"
                    :key="img"
                    :src="img"
                    alt="paymentImg"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td></td>
              <td>
                <router-link to="/returns" active-class="selected">
                  {{ t("global.footer.returnsInformation") }}
                </router-link>
              </td>
            </tr>

            <tr>
              <td></td>
              <td>
                <router-link to="/delivery" active-class="selected">
                  {{ t("global.footer.deliveryInformation") }}
                </router-link>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
      <div class="middle">
        <a v-for="item in icon" :key="item" :href="item.link" target="_blank">
          <img :src="item.img" alt="icon" />
        </a>
      </div>
      <div class="bottom">©2020 Rightphone All Rights Reserved <a href="https://beian.miit.gov.cn">粤ICP备2020104352号</a></div>
      <div class="r-divider" role="separator"></div>
    </div>
  </footer>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { Divider } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Footer',
  components: {
    ...importComponents(Divider),
  },
  setup() {
    const { t } = useI18n();

    const icon = [
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/facebookIcon.png',
        link: 'https://www.facebook.com/Rightphone-ES-102349785306282',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/tubing.png',
        link: 'https://www.youtube.com/channel/UC22zdklCHDZTqzrxBHAFRGA',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/twitterIcon.png',
        link: 'https://twitter.com/rightphone_com',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/insIcon.png',
        link: 'https://www.instagram.com/rightphonees/',
      },
    ];
    const payment = [
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/visa@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/masterCard@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/americanExpress@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/jCB@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/paypay.png',
    ];

    return {
      icon,
      t,
      payment,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
.r-global-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #d9d9d9;

  .r-container {
    padding: 60px 0;
    .above {
      display: flex;
      justify-content: space-between;
      .logo {
        img {
          width: 240px;
        }
      }

      .footer-link {
        display: flex;
        width: 900px;
        justify-content: space-between;
        h3 {
          height: 19px;
          font-size: 15px;
          font-weight: bold;
          color: #000000;
          line-height: 19px;
          margin-bottom: 19px;
        }
        .footer-col {
          ul {
            padding-left: 0px;
            li {
              font-size: 13px;
              font-weight: 400;
              color: #545454;
              line-height: 16px;
              list-style: none;
              margin-bottom: 16px;
              &:hover {
                color: $theme-color;
              }
            }
            a.selected{
              li{
                color: $theme-color;
              }
            }
          }
        }
        .footer-icon {
          display: flex;
          flex-direction: column;
            .payment {
              display: flex;
              img {
                width: 32px;
                height: 21px;
                margin-right: 10px;
              }
            }
            .r2{
              margin-top:60px;
              img{
                width: 146px;
                height: 62px;
              }

            }
        }
      }

      // .link {
      //   width: 900px;
      //   thead {
      //     font-size: 15px;
      //     color: $font-color;
      //     font-weight: bold;
      //     text-align: -webkit-left;
      //     th {
      //       width: 300px;
      //     }
      //   }

      //   tbody {
      //     td {
      //       font-size: 13px;
      //       color: $font-color-weak;
      //       font-weight: 400;

      //       a {
      //         color: $font-color;
      //         cursor: pointer;
      //         font-size: 13px;
      //         line-height: 16px;

      //         &:hover {
      //           color: $theme-color;
      //         }

      //         &.selected {
      //           color: $theme-color;
      //         }
      //       }

      //       .payment {
      //         display: flex;
      //         img {
      //           width: 32px;
      //           height: 21px;
      //           margin-right: 10px;
      //         }
      //       }
      //       .payments {
      //         display: flex;
      //         width: 125px;
      //         justify-content: center;
      //         img {
      //           width: 32px;
      //           height: 21px;
      //           margin-right: 10px;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .middle {
      a {
        display: inline-block;
        width: 49px;
        height: 49px;
        margin-right: 13px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ant-divider-horizontal {
      height: 1px;
      color: #e4e4e4;
      background: #e4e4e4;
    }

    .bottom {
      font-size: 13px;
      color: $font-color-weak;
      font-weight: 400;
      margin: 49px 0 30px;
      a {
        text-decoration: underline;
        padding-left: 6px;
      }
    }
  }
  .r-divider {
    height: 1px;
    color: #e4e4e4;
    background: #e4e4e4;
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 24px 0;
    position: relative;
    top: -0.06em;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
  }
}
</style>
