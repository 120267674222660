<template>
    <div class="r-goods-search">
        <input
            class="input"
            type="search"
            :placeholder="`${t('global.header.search')}`"
            v-model="keyword"
            @keypress="onKeyPress"
        />
        <button class="button" @click="onClick"><img src="@/assets/icons/search.svg" alt="search"/></button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/components/Alert';

export default defineComponent({
  setup() {
    const router = useRouter();

    const keyword = ref('');

    const { t } = useI18n();

    const onClick = () => {
      // const reg = /[^0-9a-zA-Z_]/g;
      const reg = /[^0-9a-zA-Z_\u2E80-\uD7FF\s]/g;
      if (keyword.value.trim() !== '' && !reg.test(keyword.value)) {
        router.push(`/search?keyword=${keyword.value}`);
        keyword.value = '';
      } else {
        const modal = Alert.error(t('global.searchInfo'));
        setTimeout(() => {
          modal.destroy();
        }, 2000);
      }
    };

    const onKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onClick();
        (e.target as HTMLInputElement).blur();
      }
    };

    return {
      keyword,
      onClick,
      onKeyPress,
      t,
    };
  },
});
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
.r-goods-search {
    border: 2px solid $theme-color;
    border-radius: 2px;
    display: inline-block;
    min-width: 239px;
    max-width: 380px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &:focus-within {
        box-shadow: 0 0 0 3px rgba($theme-color, 20%);
    }
    .input {
        border: 0;
        outline: none;
        padding: 6px 12px;
        width: 100%;
    }
    .button {
        background-color: $theme-color;
        border: 0;
        border-radius: 0;
        max-width: 90px;
        width: 27%;
        height: 38px;
        outline: none;
        &:hover {
            background: #c43940;
        }
        img {
            width: 20px;
            height: 20px;
        }
    }
}
</style>
