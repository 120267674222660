<template>
    <div>
        <a-popover v-if="cartLists.length === 0 && !cartNumber" placement="bottom">
            <template #content>
                <p>{{t('indexCartAction.p1')}}</p>
            </template>
            <img src="@/assets/icons/cart.svg" alt="cartIcon" />
            <slot/>
        </a-popover>
        <span v-else>
            <a-badge :count="cartNumber">
                <img src="@/assets/icons/cart.svg" alt="cartIcon" :class="{ 'shake': shakeState }"/>
            </a-badge>
            <slot/>
        </span>
    </div>
</template>
<script lang='ts'>
import importComponents from '@/utils/import-components';
import { computed, defineComponent, watch } from 'vue';
import { Popover, Badge } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexCartAction',
  components: {
    ...importComponents(
      Popover,
      Badge,
    ),
  },
  setup() {
    const { t } = useI18n();

    const { state } = useStore();

    const cartNumber = computed(() => state.cartNumber);

    const cartLists = computed(() => state.cartStore.cartItems);

    const shakeState = computed(() => state.cartShake);

    return {
      cartNumber,
      shakeState,
      cartLists,
      t,
    };
  },
});
</script>
<style lang='scss' scoped>

:deep(.ant-badge-count, .ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component) {
    top: -4px;
    right: 1px;
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 10px;
    line-height: 14px;
}
img {
    padding-right: 10px;
}
.shake {
  transform-origin: center center;
  animation-name: shakeTopx;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}
@keyframes shakeTopx {
  0%,
  100% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  }
  5% {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  }
  10%,
  20%,
  30% {
  -webkit-transform: rotate(-8deg);
          transform: rotate(-8deg);
  }
  15%,
  25%,
  35% {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  }
  40% {
  -webkit-transform: rotate(-4deg);
          transform: rotate(-4deg);
  }
  45% {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  }
  50% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
}
</style>
