
import { defineComponent } from 'vue';
import importComponents from '@/utils/import-components';
import { Divider } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Service',
  components: {
    ...importComponents(
      Divider,
    ),
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
