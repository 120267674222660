
import {
  defineComponent,
  onBeforeMount,
} from 'vue';
import Cookies from 'js-cookie';
import {
  BackTop, Breadcrumb, ConfigProvider,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
import importComponents from '@/utils/import-components';
import GlobalFooter from '@/components/common/GlobalFooter';
import GlobalHeader from '@/components/common/GlobalHeader';
import { touristToken } from '@/services';
import { useStore } from '@/store';
import http from '@/utils/http';
import CookiePolicy from '@/components/footerlink/CookiePolicy.vue';

export default defineComponent({
  name: 'Home',
  components: {
    GlobalFooter,
    GlobalHeader,
    CookiePolicy,
    ...importComponents(
      BackTop,
      Breadcrumb,
      Breadcrumb.Item,
      ConfigProvider,
    ),
  },

  setup() {
    const { dispatch } = useStore();
    const router = useRouter();

    http.initialize(router, dispatch);

    onBeforeMount(() => {
      dispatch('getGoodsCategory');
      if (Cookies.get('token')) {
        dispatch('getUser');
        dispatch('getUserCartNum');
      } else {
        dispatch('initVisitorCart');
        if (!localStorage.getItem('touristToken')) {
          localStorage.setItem('touristToken', Date.parse(new Date().toString()) + Math.random().toFixed(2).toString());
          touristToken(localStorage.getItem('touristToken') as string);
        }
      }
    });

    window.name = 'rightphone';

    return {
    };
  },
});
