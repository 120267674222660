import { createStore, useStore as useStoreFromVuex } from 'vuex';
import type { InjectionKey } from 'vue';
import Cookies from 'js-cookie';
import { getGoodsCategory, IndexAccount } from '@/services';
import { addCart, firstLoginToCart, visitorCart } from '@/services/cart';
import { getUserInfo } from '@/services/user';
import { Alert } from '@/components/Alert';
import type { CategoryRes, CategoryData } from '@/services/interface/response';
import type { AddCart } from '@/services/interface/request';
import i18n from '@/i18n';
import shoppingCartStore from './modules/shopping-cart';
import type { Category } from './model';

const rootStore = createStore({
  state: {
    userInfo: {
      userId: 0,
      userEmail: '',
      userLastName: '',
      userFirstName: '',
    },
    cartNumber: parseInt(Cookies.get('cartNumber') as string, 10) || 0,
    ShoppingCart: [] as AddCart[],
    cartShake: false,
    categories: [] as Category[],
  },
  mutations: {
    updateCartNumber(state, payload) {
      state.cartNumber = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    cleanUserInfo(state) {
      state.userInfo = {
        userId: 0,
        userEmail: '',
        userLastName: '',
        userFirstName: '',
      };
    },
    getVisitorCart(state) {
      if (Cookies.get('cart')) {
        state.ShoppingCart = JSON.parse(Cookies.get('cart') as string);
        state.cartNumber = parseInt(Cookies.get('cartNumber') as string, 10);
      } else {
        state.cartNumber = 0;
      }
    },
    cleanVisitorCart(state) {
      Cookies.remove('cart');
      Cookies.remove('cartNumber');
      state.cartNumber = 0;
      state.ShoppingCart = [];
    },
    setUserCartNum(state, payload) {
      state.cartNumber = payload;
    },
    addToCart(state, payload: AddCart) {
      // 如果是游客加购，判断本地存的购物车数量有没有超过库存量
      const inCartSku = (state.ShoppingCart as AddCart[]).find((item) => item.skuId === payload.skuId);
      if (inCartSku && inCartSku.quantity + 1 > payload.realityStock) {
        const modal = Alert.error(i18n.global.t('shipping.overstockSku'));
        setTimeout(() => modal.destroy(), 2000);
        return;
      }
      if (!state.ShoppingCart.some((item: AddCart) => item.skuId === payload.skuId)) {
        state.ShoppingCart.push(payload as never);
      } else {
        const idx = state.ShoppingCart.findIndex((item: AddCart) => item.skuId === payload.skuId);
        state.ShoppingCart[idx].quantity += 1;
      }
      if (!Cookies.get('token')) {
        const sum = state.ShoppingCart.reduce((prev, curr) => prev + curr.quantity, 0);
        state.cartNumber = sum;
        Cookies.set('cart', state.ShoppingCart);
        Cookies.set('cartNumber', `${sum}`);
      }
    },
    updateVisitorCart(state, payload: AddCart[]) {
      Cookies.set('cart', payload);
      state.ShoppingCart = payload;
      const sum = payload.reduce((prev, curr) => prev + curr.quantity, 0);
      Cookies.set('cartNumber', `${sum}`);
      state.cartNumber = sum;
    },
    changeVisitorCart(state, cart) {
      const idx = state.ShoppingCart.findIndex((item: AddCart) => item.skuId === cart.skuId);
      state.ShoppingCart[idx].quantity = cart.quantity;
      state.ShoppingCart = state.ShoppingCart.filter((item: AddCart) => item.quantity !== 0);
      Cookies.set('cart', state.ShoppingCart);
      Cookies.set('cartNumber', state.ShoppingCart.length.toString());
      if (state.ShoppingCart.length === 0) {
        state.cartNumber = 0;
      }
    },
    updateUserName(state, payload) {
      state.userInfo.userLastName = payload.userLastName;
      state.userInfo.userFirstName = payload.userFirstName;
    },
    reverseCartShake(state) {
      state.cartShake = !state.cartShake;
    },
    updateCategories(state, payload: CategoryRes['data']) {
      const toState = (item: CategoryData) => {
        const result: Category = {
          id: item.categoryId,
          name: item.categoryName,
          text: item.categoryName.toLowerCase().replace(/\s/g, '-'),
          subcategories: [],
        };
        if (item.secondLevelCategory && item.secondLevelCategory.length) {
          result.subcategories = item.secondLevelCategory.map(toState);
        } else {
          delete result.subcategories;
        }
        return result;
      };
      state.categories = payload.map(toState);
    },
  },
  actions: {
    async initVisitorCart(ctx) {
      ctx.commit('getVisitorCart');
    },
    async getUserCartNum(ctx) {
      const res = await IndexAccount();
      if (res.success) {
        ctx.commit('setUserCartNum', res.data.quantity);
        if (res.data.cart?.length > 0) {
          ctx.commit('updateCartItems', res.data.cart);
        }
      }
    },
    async getUser(ctx) {
      const res = await getUserInfo();
      if (res.success) {
        // lastName存放在sessionStorage，会话关闭后没有，避免个人信息泄露
        sessionStorage.setItem('userFirstName', res.data.userFirstName);
        // 提交订单，使用信用卡需要邮箱
        sessionStorage.setItem('userEmail', res.data.userEmail);
        ctx.commit('setUserInfo', res.data);
      }
    },
    async signOut(ctx) {
      Cookies.remove('token');
      sessionStorage.removeItem('userFirstName');
      sessionStorage.removeItem('userEmail');
      ctx.commit('cleanUserInfo');
      ctx.commit('cleanVisitorCart');
    },
    // 通过注册登录，把Cookies里的cart上传服务器 改成登录就把游客购物车提交，然后清空本地
    async firstLoginToCart(ctx) {
      // if (ctx.state.ShoppingCart.length !== 0) {
      const res = await firstLoginToCart(ctx.state.ShoppingCart);
      if (res.success) {
        ctx.commit('cleanVisitorCart');
        ctx.commit('setUserCartNum', res.data);
      }
      // }
    },
    async ADDTOCART(ctx, goods: AddCart) {
      if (Cookies.get('token')) {
        const res = await addCart(goods);
        if (!res.success) {
          if (res.code === 51200) {
            const modal = Alert.error(i18n.global.t('shipping.overstockSku'));
            setTimeout(() => {
              modal.destroy();
            }, 2000);
          } else {
            const modal = Alert.error(res.message);
            setTimeout(() => {
              modal.destroy();
            }, 2000);
          }
        } else {
          ctx.commit('addToCart', goods);
          ctx.commit('setUserCartNum', res.data);
        }
      } else {
        ctx.commit('addToCart', goods);
      }
    },
    async getVisitorCart(ctx) {
      const res = await visitorCart(ctx.state.ShoppingCart);
      const { success, data } = res;
      if (success) {
        // console.log(data);
        data.cartList.forEach((item) => {
          if (item.quantity > item.realityStock) {
            // eslint-disable-next-line no-param-reassign
            item.quantity = item.realityStock;
            ctx.dispatch('getVisitorCart', {
              skuId: item.skuId,
              goodsId: item.goodsId,
              quantity: item.quantity,
            });
          }
        });

        ctx.commit('updateCartItems', data.cartList);
        ctx.commit('setPrice', res.data.cartPrice);
        const newCart = data.cartList.map(({ goodsId, skuId, quantity }) => ({
          goodsId,
          skuId,
          quantity,
        }));
        ctx.commit('updateVisitorCart', newCart);
      }
    },
    async changeOrRemoveVisitorCart(ctx, cart) {
      ctx.commit('changeVisitorCart', cart);
    },
    async changeCartShake(ctx) {
      ctx.commit('reverseCartShake');
    },
    async getGoodsCategory(ctx) {
      const res = await getGoodsCategory();
      if (res.success && res.data.length) {
        ctx.commit('updateCategories', res.data);
      }
    },
    async removeVisitorCart(ctx) {
      ctx.commit('cleanVisitorCart');
    },
  },
  modules: {
    // shoppingCart: shoppingCartStore, //行长坑人，这个导致接口会调两次
    cartStore: shoppingCartStore,
  },
});

type RootStore = typeof rootStore;

export function useStore(injectKey?: InjectionKey<RootStore> | string) {
  return useStoreFromVuex(injectKey);
}

export default rootStore;
