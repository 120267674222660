<template>
    <div
        class="card"
        :class="{ 'has-add-to-card-btn': btn }"
        @click="() => router.push(`/goods/${goodsId}?s=${skuId}`)"
    >
        <div class="goods-img">
            <picture-box :src="img" :alt="goodsName"/>
        </div>
        <cart-btn
        v-if="realityStock > 0"
            :goodsId="goodsId"
            :skuId="skuId"
            :realityStock="realityStock"
            />
        <div class="text">
            <p class="goods-name">
                {{ goodsName }}
                {{ goodsAttr ? Object.values(goodsAttr).map((item) => item).join(' ') : '' }}
            </p>
            <span class="current-price">{{t('global.currency')}}{{currentPrice}}</span>
            <span class="original-price">{{t('global.currency')}}{{originalPrice}}</span>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import CartBtn from '@/components/common/CartBtn.vue';
import PictureBox from '@/components/common/PictureBox.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexGoodsCard',
  props: {
    goodsId: {
      type: Number,
      requried: true,
    },
    skuId: {
      type: [String, Number],
      requried: true,
    },
    img: {
      type: String,
      requried: true,
    },
    goodsName: {
      type: String,
      requried: true,
    },
    currentPrice: {
      type: [Number, String],
      requried: true,
    },
    originalPrice: {
      type: [Number, String],
      requried: true,
    },
    goodsAttr: {
      type: Object,
    },
    realityStock: {
      type: Number,
    },
    btn: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CartBtn,
    PictureBox,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();

    return {
      router,
      t,
    };
  },
});
</script>

<style lang='scss' scoped>
@import '@/assets/styles/variables.scss';
.card {
    width: 268px;
    height:320px;
    padding: 12px;
    margin-right: 44px;
    color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    position: relative;
    &:hover {
        cursor: pointer;
    }
    &.has-add-to-card-btn:hover {
        .ant-btn-primary {
            display: block;
        }
    }
    .ant-btn-primary {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // width: 100%;
        border-radius: 5px;
        background-color: $theme-color;
        border-color: $theme-color;
        .cart-btn-icon {
            width: 21px;
            height: 19px;
            margin-right: 10px;
            display: inline-block;
        }
    }
    .goods-img {
        text-align: center;
        height: 65%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .text {
        p {
            margin: 0;
        }
        .goods-name {
            font-size: 16px;
            color: $font-color;
            padding: 24px 0 0;
            // height: 80px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden
        }
        .original-price {
            font-size: 14px;
            color: #969696;
            text-decoration: line-through;
            line-height: 33px;
            padding-left: 20px;
        }
        .current-price {
            font-size: $font-size;
            color: $theme-color;
            font-weight: bold;
        }
    }
}
</style>
