import http from '@/utils/http';
import type * as Response from './interface/response';

const modulePath = '/index';

/**
 * @description: 首页导航栏
 */
export function indexMenu() {
  return http.client<Response.IndexNav>(`${modulePath}/get_index_category`);
}

export function getGoodsCategory() {
  return http.client<Response.CategoryRes>(`${modulePath}/get_index_category`);
}

/**
 * @description: 主要获取购物车数量
 * @param {*}
 * @return {*}
 */
export function IndexAccount() {
  return http.client<Response.UserAccount>(`${modulePath}/get_index_account`);
}

/**
 * @description: 首页iphone活动版块
 */
export function indexActivity() {
  return http.client<Response.IndexSwiper>(`${modulePath}/get_index_activity`);
}

/**
 * @description: 进入网站的新用户，用于统计流量
 * @param data
 * @return {*}
 */
export function touristToken(data: string) {
  return http.client<Response.IsSuccess>(`${modulePath}/add_new_user_token`, {
    method: 'POST',
    data,
  });
}
export default {};
