
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue';
import { Carousel } from 'ant-design-vue';
import IndexGoodsCard from '@/components/index/IndexGoodsCard.vue';

interface Goods {
  img: string;
  name: string;
  currentPrice: string;
  originalPrice: string;
  skuId: string;
  goodsAttr: {
    capacity: string;
    carrier: string;
    color: string;
  };
}

export default defineComponent({
  name: 'GoodsSwiper',
  props: {
    goods: {
      type: Array as () => Goods[],
      required: true,
    },
  },
  components: {
    IndexGoodsCard,
    'left-icon': LeftCircleFilled,
    'right-icon': RightCircleFilled,
    ...importComponents(
      Carousel,
    ),
  },
  setup() {
    // 轮播图根据窗口可视宽度展示
    const cardNum = ref(5);

    const variableWidth = ref(false);

    const setCardNum = () => {
      if (document.body.clientWidth <= 1280) {
        variableWidth.value = false;
        cardNum.value = 4;
      } else {
        variableWidth.value = false;
        cardNum.value = 5;
      }
    };

    window.onresize = () => {
      setCardNum();
    };

    onBeforeMount(() => {
      setCardNum();
    });

    return {
      variableWidth,
      cardNum,
    };
  },
});
