import { createI18n } from 'vue-i18n';
import type { LocaleMessages, VueMessageType } from 'vue-i18n';

// https://github.com/intlify/vue-i18n-next/issues/198#issuecomment-750838432

export type Locale = {
  code: string;
  text: string;
  prefix: string;
  icon: string;
  url?: string;
  timezone: string;
};

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages as LocaleMessages<VueMessageType>;
}

export const ZH_HK = 'zh_HK'; // 繁体中文（香港）
export const DE_DE = 'de_DE'; // 德语
export const EN_GB = 'en_GB'; // 英语
export const EN_US = 'en_US'; // 英语（美式）
export const ES_ES = 'es_ES'; // 西班牙语
export const FR_FR = 'fr_FR'; // 法语
export const IT_IT = 'it_IT'; // 意大利语
export const PT_PT = 'pt_PT'; // 葡萄牙语
export const EN_NL = 'en_NL'; // 荷兰
export const EN_IE = 'en_IE'; // 爱尔兰

export const availableLocales: { [key: string]: Locale } = {
  // [ZH_HK]: {
  //     code: ZH_HK,
  //     text: '繁体中文(香港)',
  // },
  // [DE_DE]: {
  //   code: DE_DE,
  //   text: 'Deutschland',
  //   prefix: 'de',
  //   icon: 'https://www.countryflags.io/DE/flat/64.png',
  // },
  [ES_ES]: {
    code: ES_ES,
    text: 'España',
    prefix: 'es',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/es_64.png',
    url: 'https://www.rightphone.es',
    timezone: 'Europe/London',
  },
  [FR_FR]: {
    code: FR_FR,
    text: 'France',
    prefix: 'fr',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/fr_64.png',
    url: 'https://www.rightphone.fr',
    timezone: 'Europe/London',
  },
  // [IT_IT]: {
  //   code: IT_IT,
  //   text: 'Italia',
  //   prefix: 'it',
  //   icon: 'http://www.countryflags.io/IT/flat/64.png',
  // },
  [EN_GB]: {
    code: EN_GB,
    text: 'United Kingdom',
    prefix: 'uk',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/uk_64.png',
    url: 'https://www.righthopne.co.uk',
    timezone: 'Europe/London',
  },
  [EN_US]: {
    code: EN_US,
    text: 'United States',
    prefix: 'us',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/us_64.png',
    url: 'https://us.righthopne.com',
    timezone: 'America/Los_Angeles',
  },
  // [PT_PT]: {
  //     code: PT_PT,
  //     text: 'Portugal',
  //     url: 'https://pt.rightphone.com',
  //     icon: 'https://www.countryflags.io/PT/flat/64.png',
  // },
  [EN_NL]: {
    code: EN_NL,
    text: 'Nederland',
    prefix: 'nl',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/nl_64.png',
    url: 'https://www.rightphone.nl',
    timezone: 'Europe/London',
  },
  [EN_IE]: {
    code: EN_IE,
    text: 'Ireland',
    prefix: 'ie',
    icon: 'https://rightphone.oss-eu-west-1.aliyuncs.com/mall/countryflags/ie_64.png',
    url: 'https://www.rightphone.ie',
    timezone: 'Europe/London',
  },
};

// export const supportLocaleCodes = Object.keys(supportLocales);
// export const supportLocaleTexts = Object.values(supportLocales).map(locale => locale.text);

let locale = EN_US;
// 闭包是为了不造成变量污染
(() => {
  const { hostname } = window.location;
  // 判断是否是本地开发，通过 IP 访问，如果是的可以通过修改 localStore 的 值变更语言
  if (/(localhost|192)/.test(hostname)) {
    const l = localStorage.getItem('locale');
    locale = l ?? 'es_ES';
    return;
  }

  const suffix = (hostname.match(/(es|fr|nl|ie|co\.uk|com)/) as RegExpMatchArray)[0];
  if (suffix === 'es') {
    locale = ES_ES;
    return;
  }
  if (suffix === 'fr') {
    locale = FR_FR;
    return;
  }
  if (['co.uk'].includes(suffix)) {
    locale = EN_GB;
    return;
  }
  // 如果是预览环境，会切出 code
  let prefix = hostname.split('.')[0];
  if (prefix.includes('preview')) {
    prefix = prefix.replace('preview-', '');
  }
  const keys = Object.keys(availableLocales);
  for (let i = 0; i < keys.length; i += 1) {
    if (availableLocales[keys[i]].prefix === prefix) {
      locale = keys[i];
      break;
    }
  }
})();

(document.querySelector('html') as HTMLHtmlElement).setAttribute('lang', locale);

export default createI18n({
  legacy: false,
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || EN_US,
  messages: loadLocaleMessages(),
});
