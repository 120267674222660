<template>
    <header class="r-global-header">
        <div class="r-container-l">
            <div class="nav-top">
                <div class="logo-wrapper">
                    <div>
                        <a class="logo" href="/">
                            <img src="@/assets/logo.svg" alt="logo"/>
                        </a>
                        <!-- <div>{{t('global.header.slogan')}}</div> -->
                    </div>
                </div>
                <div class="search-wrapper">
                    <goods-search />
                </div>
                <div class="action-wrapper">
                    <div>
                        <div class="action" @click="() => router.push('/login')" v-if="userFirstName === '' && !email">
                            <img src="@/assets/icons/person.svg" alt="personIcon">
                            <span>{{ t('global.header.signIn') }}</span>
                        </div>
                        <a-dropdown v-else :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                            <div class="action">
                                <img src="@/assets/icons/person.svg" alt="personIcon">
                                <span>{{userFirstName !== '' ? userFirstName : email}}</span>
                            </div>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item>
                                        <router-link to="/user/my-orders">{{ t('global.header.orders') }}</router-link>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <router-link to="/user/profile">{{ t('global.header.profile') }}</router-link>
                                    </a-menu-item>
                                    <a-menu-item @click="signOut">
                                        <span>{{ t('global.header.signOut') }}</span>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown>
                        <div class="action cart" @click="toCart">
                            <index-cart-action class="cart-content">
                                <span>{{ t('global.header.cart') }}</span>
                            </index-cart-action >
                        </div>
                        <RegionSelector />
                    </div>
                </div>
                <div class="contact-us">
                    <router-link to="/contact">
                        {{ t("global.footer.contact") }}
                    </router-link>
                </div>
            </div>
            <div class="nav-slogan">
              <span>{{t('global.header.slogan')}}</span>
            </div>
            <div class="nav-bottom">
                <GoodsCategory />
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  computed, defineComponent,
} from 'vue';
import { useStore } from 'vuex';
import IndexCartAction from '@/components/index/IndexCartAction.vue';
import { useRouter, useRoute } from 'vue-router';
import { Dropdown, Menu, Button } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { touristToken } from '@/services';
import http from '@/utils/http';
import GoodsSearch from './GoodsSearch.vue';
import GoodsCategory from './GoodsCategory.vue';
import RegionSelector from './RegionSelector.vue';

export default defineComponent({
  name: 'Header',
  components: {
    GoodsSearch,
    IndexCartAction,
    RegionSelector,
    GoodsCategory,
    ...importComponents(
      Dropdown,
      Menu,
      Menu.Item,
      Button,
    ),
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { state, dispatch } = useStore();
    const { t } = useI18n();

    const userFirstName = computed(() => state.userInfo.userFirstName);

    const email = computed(() => state.userInfo.userEmail);

    const cartNumber = computed(() => state.cartNumber);

    const cartLists = computed(() => state.cartStore.cartItems);

    const signOut = () => {
      dispatch('signOut');
      router.push('/');
      // 清空登录状态的购物车
      dispatch('cleanCart');
      http.clearAuthorization();
      if (!localStorage.getItem('touristToken')) {
        localStorage.setItem('touristToken', Date.parse(new Date().toString()) + Math.random().toFixed(2).toString());
        touristToken(localStorage.getItem('touristToken') as string);
      }
    };

    const toCart = () => {
      dispatch('getCartItems');
      if (cartLists.value.length > 0 || cartNumber.value) {
        router.push('/shopping-cart');
      }
    };

    return {
      route,
      router,
      userFirstName,
      signOut,
      toCart,
      t,
      email,
    };
  },
});
</script>

<style lang="scss">
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .r-global-header {
        border-bottom: 1px solid #E1E1E1;
        background-color: #FFFFFF;
        position: sticky;
        top: 0;
        z-index: 99;
        .nav-top {
            display: flex;
            align-items: center;
            padding: 30px 0 9px;
            justify-content: space-between;
            position: relative;
            .logo-wrapper {
                > div {
                    min-width: 180px;
                    max-width: 236px;
                    width: 100%;
                    display: flex;
                }
                .logo {
                    display: block;
                    font-size: 0;
                    cursor: pointer;
                }
                img {
                    width: 100%;
                }
            }
            .search-wrapper {
                width: 380px;
                text-align: center;
            }
            .action-wrapper {
                display: flex;
                justify-content: flex-end;
                > div {
                    display: flex;
                    align-items: center;
                }
                .action {
                    cursor: pointer;
                    padding-right: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    img {
                        // padding-right: 10px;
                    }
                    span {
                        vertical-align: text-top;
                    }
                }
                .cart {
                  .cart-content{
                    > span {
                      display: flex;
                      justify-content: center;
                      align-items: flex-end;
                    }
                  }
                    .ant-badge-count {
                        background-color: $theme-color;
                    }
                }
            }
            .contact-us{
                width: 81px;
                height: 24px;
                font-size: 12px;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;
                line-height: 24px;
                background: #E65050;
                position: absolute;
                right: 0px;
                top: 0px;
                cursor: pointer;
                a{
                    color: inherit;
                }
            }
        }
        .nav-slogan{
          line-height: 12px;
          margin-left:21px;
          margin-bottom:25px;
          span{
            font-size: 10px;
            font-weight: bold;
            color: rgba(28, 28, 28, 0.56);
          }
        }
    }
</style>

function clearAuthorization() {
  throw new Error('Function not implemented.');
}
