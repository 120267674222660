import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/cart';

/**
 * @description: 添加购物车
 * @param {*}
 * @return {*}
 */
export function addCart(data: Request.AddCart | Request.AddCart[]) {
  return http.client<Response.IsSuccess>(`${modulePath}/add_cart`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 成功注册后自动登录，将Cookies里的cart统一添加购物车
 * @param {*}
 * @return {*}
 */
export function firstLoginToCart(data: Request.AddCart[]) {
  return http.client<Response.CartNum>(`${modulePath}/batch_add_cart`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 查询购物车
 * @param {*}
 * @return {*}
 */
export function cartItem() {
  return http.client<Response.CartList>(`${modulePath}/get_cart`, {
  });
}

/**
 * @description: 游客进购物车页
 * @param {Request} params
 * @return {*}
 */
export function visitorCart(data: Request.AddCart[]) {
  return http.client<Response.VisitorCart>(`${modulePath}/get_visitor_cart`, {
    method: 'POST',
    data,
  });
}

/**
 * @description: 删除购物车个别商品
 * @param {*}
 * @return {*}
 */
export function deleteCart(params: Request.DeleteCart) {
  return http.client<Response.IsDelete>(`${modulePath}/delete_cart`, {
    method: 'POST',
    params,
  });
}

/**
 * @description: 查询购物车中商品价格
 * @param {*}
 * @return {*}
 */
export function cartPrice() {
  return http.client<Response.CartPrice>(`${modulePath}/get_cart_price`, {
  });
}

/**
 * @description: 更新购物车商品;
 * @param {*}
 * @return {*}
 */
export function updateCart(data: Request.UpdateCart) {
  return http.client<Response.UpdateCart>(`${modulePath}/update_cart`, {
    method: 'POST',
    data,
  });
}

export default {};
