import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Cookies from 'js-cookie';
import userRoutes from './user';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  ...userRoutes,
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/category/:categoryName',
    name: 'GoodsList',
    component: () => import(/* webpackChunkName: "goods-list-for-category" */ '@/views/GoodsListForCategory.vue'),
    meta: {
      keepAlive: true,
    },
    children: [
      {
        path: '/category/:categoryName/goods/:goodsName',
        name: 'GoodsList',
        component: () => import(/* webpackChunkName: "goods-list-for-category" */ '@/views/GoodsListForCategory.vue'),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "goods-lois-for-category" */ '@/views/GoodsListForCategory.vue'),
  },
  {
    path: '/goods/:goodsId',
    name: 'GoodsDetail',
    component: () => import(/* webpackChunkName: "goods-detail" */ '@/views/GoodsDetail.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue'),
    children: [
      {
        path: '/checkout:/:errorCode',
        component: () => import(/* webpackChunkName: "checkout" */ '@/views/Checkout.vue'),
      },
    ],
  },
  {
    path: '/order-complete/:orderNumber',
    name: 'OrderComplete',
    component: () => import(/* webpackChunkName: "order-complete" */ '@/views/order/OrderComplete.vue'),
  },
  {
    path: '/shopping-cart',
    name: 'ShoppingCart',
    component: () => import(/* webpackChunkName: "shopping-cart" */ '@/views/ShoppingCart.vue'),
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import(/* webpackChunkName: "faqs" */ '@/views/footerlink/Faqs.vue'),
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import(/* webpackChunkName: "delivery" */ '@/views/footerlink/Delivery.vue'),
  },
  {
    path: '/support-index',
    name: 'SupportIndex',
    component: () => import('@/views/footerlink/support/SupportIndex.vue'),
    redirect: '/support',
    children: [
      {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/footerlink/support/Support.vue'),
      },
      {
        path: '/support/:type',
        name: 'SupportDetail',
        component: () => import('@/views/footerlink/support/SupportDetail.vue'),
      },
    ],
  },
  {
    path: '/returns',
    name: 'Returns',
    component: () => import('@/views/footerlink/Returns.vue'),
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import('@/views/footerlink/aboutus/AboutUs.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/footerlink/Contact.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
  },
  {
    path: '/device-conditions',
    name: 'DeviceConditions',
    component: () => import('@/views/footerlink/DeviceConditions.vue'),
  },
  {
    path: '/warranty-information',
    name: 'WarrantyInformation',
    component: () => import('@/views/footerlink/WarrantyInformation.vue'),
  },
  {
    path: '/returns-information',
    name: 'ReturnsInformation',
    component: () => import('@/views/footerlink/ReturnsInformation.vue'),
  },
  {
    path: '/shipping-information',
    name: 'ShippingInformation',
    component: () => import('@/views/footerlink/ShippingInformation.vue'),
  },
  {
    path: '/payment-method',
    name: 'PaymentMethod',
    component: () => import('@/views/footerlink/PaymentMethod.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/footerlink/PrivacyPolicy.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: () => import('@/views/footerlink/CookiePolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      if (to.name === 'GoodsList' && from.name === 'GoodsDetail') {
        return savedPosition;
      }
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to) => {
  if (to.matched[0].path.split('/')[1] === 'user' && !Cookies.get('token')) {
    router.push('login');
  }
});

export default router;
