<template>
    <div class="home">
        <div class="banner">
            <indexBanner/>
            <!-- <img src="/images/index/indexBanner.jpg" alt="banner" /> -->
        </div>
        <div class="kintex-iphone">
            <index-section :title="t('stock')">
                <goods-swiper :goods="goods" />
            </index-section>
        </div>
        <div class="block">
            <!-- <div class="block-above">
                <a href="/goods/38?s=513"><picture-box src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/sansung-banner-20210304.png" alt="iWatchImg" /></a>
            </div> -->
            <div class="block-bottom">
              <a href="/goods/20"><picture-box src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/iphone8-banner-20210304.png" alt="newCollectionImg" /></a>
              <a href="/category/apple?sort=1"><picture-box src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/bestSelling.png" alt="bestSellingImg" /></a>
            </div>
        </div>
        <!-- 未上线商品 隐藏代码 -->
        <!-- <div class="apple-watch">
            <index-section title="KINTEX IPHONE IPAD">
                <div class="watch">
                    <div class="watch-left">
                        <img src="/images/index/watch1.png" alt="apple-watch">
                    </div>
                    <div class="watch-right">
                        <img src="/images/index/watch2.png" alt="apple-watch">
                        <img src="/images/index/watch3.png" alt="apple-watch">
                    </div>
                </div>
            </index-section>
        </div> -->
    <!-- <div class="accessories">
            <index-section title="KINTEX ACCESSORIES">
                <goods-swiper :goods="accessories" />
            </index-section>
        </div> -->
        <div class="services">
            <service />
        </div>
    </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import IndexBanner from '@/components/index/IndexBanner.vue';
import Service from '@/components/index/Service.vue';
import IndexSection from '@/components/index/IndexSection.vue';
import importComponents from '@/utils/import-components';
import GoodsSwiper from '@/components/common/GoodsSwiper.vue';
// eslint-disable-next-line import/no-cycle
import { indexActivity } from '@/services';
import type { Goods } from '@/services/interface/response';
import PictureBox from '@/components/common/PictureBox.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Home',
  components: {
    IndexBanner,
    Service,
    IndexSection,
    GoodsSwiper,
    PictureBox,
    ...importComponents(
    ),
  },
  setup() {
    const goods = ref<Goods[]>([]);
    const { t } = useI18n();

    const accessories = [
      {
        img: '/images/index/lightening.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/dataLine.png',
        name: 'Bluetooth headset',
        price: '479.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/Heaphone-AccBundBox.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/Plug.jpg',
        name: 'Bluetooth headset',
        price: '479.00',
        originalPrice: '499.00',
      },
      {
        img: '/images/index/watch-charger.jpg',
        name: 'Bluetooth headset',
        price: '489.00',
        originalPrice: '499.00',
      },
    ];

    const getIphoneSwiper = async () => {
      const res = await indexActivity();
      if (res.success) {
        goods.value = res.data.iphones.map((item) => ({
          ...item,
          img: `${item.img}?x-oss-process=image/resize,w_130,h_160`,
        }));
      }
    };

    onBeforeMount(getIphoneSwiper);

    return {
      goods,
      accessories,
      t,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

.home {
    .banner {
        height: 33.22916666666667vw;
        img {
            width: 100%;
        }
    }
    .services {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EA4F52;
        height: 132px;
    }
    .kintex-iphone {
        @include container();
    }
    .block {
        @include container();
        margin-top: 66px;
        .block-above {
            a{
                display: inline-block;
                img {
                    width: 100%;
                    cursor: pointer;
                }
            }
        }
        .block-bottom {
            display: flex;
            padding: 75px 0 60px;
            a{
                flex: 1;
                display: inline-block;
                cursor: pointer;
                &:first-child{
                    margin-right: 29px;
                }
                &:last-child{
                    margin-left: 29px;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
    .apple-watch {
        background-color: #F4F4F4;
        .watch {
            @include container();
            margin: 42px auto;
            padding: 0 0 60px;
            display: flex;
            .watch-left {
                width: 65%;
                // height: 602px;
                padding: 40px;
                display: flex;
                box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
                border-radius: 8px;
                background-color: #FFFFFF;
                img {
                    width: 100%;
                }
            }
            .watch-right {
                flex: 1;
                padding-left: 2.4%;
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                img {
                    width: 100%;
                }
            }
        }
    }
    .accessories {
        @include container();
        padding-bottom: 197px;
        .card {
            box-shadow: unset;
            border: 2px solid $footer-color;
        }
          :deep(.ant-carousel){
    .slick-list {
        margin: 0 109px;
        }
    .slick-slide, .slick-current {
        overflow: hidden;
        &>div {
            width: 268px;
            margin: auto;
        }
        .card {
            width: 93%!important;
            margin: 40px 21px 86px;
            height: 377px;
            .goods-img{
                img {
                  width: 187px;
                  height: 245px;
                  padding: 0 28px;
                }
            }
            .text {
                padding-bottom: 17px;
            }
            &:hover .ant-btn-primary {
                width: 80%;
                left: 17px;
            }
        }
    }
}
    }
}
</style>
