
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { Divider } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Footer',
  components: {
    ...importComponents(Divider),
  },
  setup() {
    const { t } = useI18n();

    const icon = [
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/facebookIcon.png',
        link: 'https://www.facebook.com/Rightphone-ES-102349785306282',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/tubing.png',
        link: 'https://www.youtube.com/channel/UC22zdklCHDZTqzrxBHAFRGA',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/twitterIcon.png',
        link: 'https://twitter.com/rightphone_com',
      },
      {
        img:
          'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/insIcon.png',
        link: 'https://www.instagram.com/rightphonees/',
      },
    ];
    const payment = [
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/visa@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/masterCard@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/americanExpress@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/jCB@2x.png',
      'https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/footer/paypay.png',
    ];

    return {
      icon,
      t,
      payment,
    };
  },
});
