import Cookies from 'js-cookie';
import { extend } from 'umi-request';
import type { Router } from 'vue-router';
import type { Dispatch } from 'vuex';
import i18n from '@/i18n';

const isDevelopment = process.env.NODE_ENV === 'development';
const prefix = 'http://192.168.0.155:34576';

const isNederlandAndIreland = i18n.global.locale.value === 'en_NL' || i18n.global.locale.value === 'en_IE';

const client = extend({
  prefix: `${isDevelopment ? prefix : ''}/api`,
  headers: {
    'Accept-Language': isNederlandAndIreland ? 'en-GB' : i18n.global.locale.value.replace('_', '-'),
  },
});

let willRemoveAuthorization = false; // 控制删除Authorization token
const setAuthorization = (token: string) => {
  willRemoveAuthorization = false;
  client.extendOptions({
    headers: {
      Authorization: token,
    },
  });
  Cookies.set('token', token, { expires: 1 / 3 });
};

const clearAuthorization = () => {
  willRemoveAuthorization = true;
  // client.extendOptions({
  //   headers: {
  //     Authorization: '',
  //   },
  // });
};

const initialize = (router: Router, dispatch: Dispatch) => {
  client.interceptors.response.use(async (response) => {
    // token过期等情况身份验证不通过时要重新登录
    if (response.status === 401 && router.currentRoute.value.path !== '/shopping-cart') {
      dispatch('signOut');
      router.push('/login');
      return response;
    }
    const newToken = response.headers.get('authorization');
    if (newToken) {
      setAuthorization(newToken);
    }

    // 解决在其他人的账户上直接访问我的'订单详情'或者'物流信息'等页的url地址时，请求失败，页面一直显示loading的问题
    const { code, message, success } = await response.clone().json();
    if (!success) {
      if (code === 21020 && message === 'no access!') {
        router.push('/404');
      }
    }

    return response;
  });
};

client.interceptors.request.use((url, options) => {
  if (willRemoveAuthorization) {
    // eslint-disable-next-line no-param-reassign
    delete (options.headers as Record<string, string>).Authorization;
  }
  return {
    url, options,
  };
});

export default {
  client,
  setAuthorization,
  initialize,
  clearAuthorization,
};
