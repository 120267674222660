
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Alert } from '@/components/Alert';

export default defineComponent({
  setup() {
    const router = useRouter();

    const keyword = ref('');

    const { t } = useI18n();

    const onClick = () => {
      // const reg = /[^0-9a-zA-Z_]/g;
      const reg = /[^0-9a-zA-Z_\u2E80-\uD7FF\s]/g;
      if (keyword.value.trim() !== '' && !reg.test(keyword.value)) {
        router.push(`/search?keyword=${keyword.value}`);
        keyword.value = '';
      } else {
        const modal = Alert.error(t('global.searchInfo'));
        setTimeout(() => {
          modal.destroy();
        }, 2000);
      }
    };

    const onKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        onClick();
        (e.target as HTMLInputElement).blur();
      }
    };

    return {
      keyword,
      onClick,
      onKeyPress,
      t,
    };
  },
});
