<template>
  <div class="cookie" v-if="cookieVisible">
    <img src="/images/index/cookie.svg" />
    <span
      >{{ t("cookie.p1") }}
      <a @click="handleModal"> {{ t("cookie.p2") }}</a></span
    >
    <button @click="handleClick">{{ t("cookie.p3") }}</button>
  </div>
  <a-modal class="privacy-policy-modal" width="1279px" v-model:visible="modalVisible" :footer="null" :closable="false">
    <div class="privacy-modal">
      <div class="title">{{ t("cookie.title") }}</div>
      <div class="content">
        <div class="content-option">
          <p class="font-weight">{{ t("cookie.cookie1.p1") }}</p>
          <p>{{ t("cookie.cookie1.p2") }}</p>
        </div>

        <div class="content-option">
          <p class="font-weight">{{ t("cookie.cookie2.p1") }}</p>
          <p v-html="t('cookie.cookie2.p2')"></p>
        </div>

        <table>
          <tr>
            <th v-for="item in table.th" :key="item">{{ item.th }}</th>
          </tr>
          <tr v-for="item in table.td" :key="item">
            <td v-for="i in item" :key="i">{{ i }}</td>
          </tr>
        </table>

        <div class="content-option">
          <p>{{ t("cookie.cookie3.p1") }}</p>
          <p v-html="t('cookie.cookie3.p3')"></p>
          <ul>
            <li v-for="item in browser" :key="item">
              <a>{{ item }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script lang="ts">
import importComponents from '@/utils/import-components';
import { useI18n } from 'vue-i18n';
import { defineComponent, onBeforeMount, ref } from 'vue';
import { Modal } from 'ant-design-vue';

export default defineComponent({
  name: 'CookiePolicy',
  components: {
    ...importComponents(Modal),
  },
  setup() {
    const { t } = useI18n();
    const modalVisible = ref<boolean>(false);
    const cookieVisible = ref<boolean>(true);
    // 隐私政策弹框
    const handleModal = () => {
      modalVisible.value = true;
    };
    const handleClick = () => {
      localStorage.setItem('cookiePolicy', 'true');
      cookieVisible.value = false;
    };

    const table = {
      th: [
        { th: t('cookie.table.th.th1') },
        { th: t('cookie.table.th.th2') },
        { th: t('cookie.table.th.th3') },
      ],
      td: [
        [
          t('cookie.table.td.td1'),
          t('cookie.table.td.td2'),
          t('cookie.table.td.td3'),
        ],
        [
          t('cookie.table.td.td4'),
          t('cookie.table.td.td5'),
          t('cookie.table.td.td6'),
        ],
        [
          t('cookie.table.td.td7'),
          t('cookie.table.td.td8'),
          t('cookie.table.td.td9'),
        ],
        [
          t('cookie.table.td.td10'),
          t('cookie.table.td.td11'),
          t('cookie.table.td.td12'),
        ],
        [
          t('cookie.table.td.td13'),
          t('cookie.table.td.td14'),
          t('cookie.table.td.td15'),
        ],
        [
          t('cookie.table.td.td16'),
          t('cookie.table.td.td17'),
          t('cookie.table.td.td18'),
        ],
        [
          t('cookie.table.td.td19'),
          t('cookie.table.td.td20'),
          t('cookie.table.td.td21'),
        ],
        [
          t('cookie.table.td.td22'),
          t('cookie.table.td.td23'),
          t('cookie.table.td.td24'),
        ],
        [
          t('cookie.table.td.td25'),
          t('cookie.table.td.td26'),
          t('cookie.table.td.td27'),
        ],
      ],
    };
    const browser = [
      'Firefox',
      'Chrome',
      'Internet',
      'Explorer',
      'Safari',
      'Opera',
    ];

    onBeforeMount(() => {
      if (!localStorage.getItem('cookiePolicy')) {
        cookieVisible.value = true;
      } else {
        cookieVisible.value = false;
      }
    });
    return {
      t,
      modalVisible,
      handleModal,
      table,
      browser,
      handleClick,
      cookieVisible,
    };
  },
});
</script>

<style lang="scss">
.cookie {
  position: fixed;
  z-index:1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  padding: 12px 36px;
  width: 1000px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #243238;
  img {
    margin-right: 13px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
  }
  a {
    color: #7dc5ff;
  }
  button {
    height: 30px;
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    color: #243238;
    line-height: 16px;
    margin-left: 34px;
    border-width: 0;
    padding: 0 12px;
  }
}
.privacy-policy-modal {
    .ant-modal-content {
    height: 70vh;
    overflow: auto;
  }
  .privacy-modal {
  padding: 36px;
  background: #f5f5f5;
  p {
    margin: 0;
    font-size: 18px;
    line-height: 35px;
  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #1c1c1c;
    line-height: 29px;
  }
  .content {
    .content-option {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 35px;
      .font-weight {
        font-weight: 600;
      }
      ul {
        display: inline-block;
        margin-bottom: 0px;
      }
      a {
        color: #0000ff;
      }
    }
  }
  table,
  td,
  th {
    border: 1px solid #979797;
    font-size: 18px;
    padding: 5px;
    text-align: center;
  }
  table {
    width: 100%;
    margin-bottom: 20px;
    th {
      font-weight: 600;
    }
    td {
      font-weight: 400;
    }
  }
}
}
</style>
