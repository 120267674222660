import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/styles/common.scss';
import i18n from './i18n';

const isPC = (() => {
  const userAgentInfo = navigator.userAgent;
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < agents.length; v += 1) {
    if (userAgentInfo.indexOf(agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
})();

if (!isPC) {
  const { hostname } = window.location;
  window.location.href = `https://m-${hostname}`;
}

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');
