
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import { Dropdown, Menu } from 'ant-design-vue';
import { availableLocales } from '@/i18n';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RegionSelector',
  components: {
    ...importComponents(Dropdown, Menu, Menu.Item),
  },
  setup() {
    const { locale } = useI18n();

    return {
      availableLocales,
      locale,
      isPre: process.env.VUE_APP_IS_PRE,
    };
  },
});
