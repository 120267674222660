
import importComponents from '@/utils/import-components';
import { Carousel } from 'ant-design-vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IndexBanner',
  components: {
    ...importComponents(Carousel),
  },
  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
});
