<template>
    <div class="service-wrapper">
        <div class="service">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/service-icon-1-210304.svg" alt="serviceIcon1" />
            <p class="title">{{t('service.p1')}}</p>
        </div>
        <a-divider type="verticl"/>
        <div class="service">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/service-icon-2-210304.svg" alt="serviceIcon1" />
            <p class="title">{{t('service.p2')}}</p>
        </div>
        <a-divider type="verticl"/>
        <div class="service">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/service-icon-3-210304.svg" alt="serviceIcon1" />
            <p class="title">{{t('service.p5')}}</p>
        </div>
        <a-divider type="verticl"/>
        <div class="service">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/service-icon-4-210304.svg" alt="serviceIcon1" />
            <p class="title">{{t('service.p3')}}</p>
        </div>
        <a-divider type="verticl"/>
        <div class="service">
            <img src="https://kintex-mall.oss-cn-shenzhen.aliyuncs.com/mall/index/service-icon-5-210304.svg" alt="serviceIcon1" />
            <p class="title">{{t('service.p4')}}</p>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import importComponents from '@/utils/import-components';
import { Divider } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Service',
  components: {
    ...importComponents(
      Divider,
    ),
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
</script>
<style lang='scss' scoped>
@import '@/assets/styles/variables.scss';
.service-wrapper{
    width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service {
    width: 354px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 49px;
        height: 49px;
    }
    p {
        margin: unset;
        margin-left: 25px;
    }
    .title {
        font-size: 16px;
        font-weight: $font-weight;
        color: #fff;
    }
}
.ant-divider, .ant-divider-vertical {
    width: 1px;
    height: 68px;
    background: #FFF;
}
</style>
